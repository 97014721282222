import moment from 'moment';
import { generateDays, generateYears } from '../utils/utils';
import avatar1 from '../assets/images/avatar1.svg';
import avatar2 from '../assets/images/avatar2.svg';
import avatar3 from '../assets/images/avatar3.svg';
import avatar4 from '../assets/images/avatar4.svg';
import avatar5 from '../assets/images/avatar5.svg';
import avatar6 from '../assets/images/avatar6.svg';

export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Username',
        ref,
      },
      label: 'Email',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: 'Password',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  username,
  psw,
  pswrepeat,
  dateofbirth,
  sex,
  male,
  female,
  pswerror,
  pswconfirmerror,
  ref,
  gdpr,
  gdprAccept,
  gdprReject,
) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: `${email}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: `${psw}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      label: `${pswrepeat}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: `${firstname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome',
      },
      label: `${lastname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    username: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'username',
        id: 'username',
        placeholder: '',
      },
      label: `${username}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      label: `${dateofbirth}`,
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'm', displayValue: `${male}` },
          { value: 'f', displayValue: `${female}` },
        ],
        placeholder: '',
        name: 'gender',
      },
      label: `${sex}`,
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: `zipcode`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    regulation: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'regulation',
        id: 'regulation',
      },
      label: '<p>Dichiaro di aver letto e accettato il <a href="">regolamento</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label:
        '<p>Dichiaro di aver letto e accettato l\'<a href="">informativa sulla privacy</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_marketing: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `${gdpr.gdpr_marketing.label}`,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'gdpr_marketing_accept',
          label: `${gdprAccept}`,
          value: 1,
        },
        {
          id: 'gdpr_marketing_reject',
          label: `${gdprReject}`,
          value: 0,
        },
      ],
    },
    gdpr_profiling: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label: `${gdpr.gdpr_profiling.label}`,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'gdpr_profiling_accept',
          label: `${gdprAccept}`,
          value: 1,
        },
        {
          id: 'gdpr_profiling_reject',
          label: `${gdprReject}`,
          value: 0,
        },
      ],
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        ref,
      },
      label: `${emailLabel}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        ref,
      },
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `${newPswLabel}`,
        ref,
      },
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `${confirmNewPsw}`,
      },
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: '',
        ref,
      },
      label: `${newPswLabel}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: '',
      },
      label: `${confirmNewPsw}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    },
    /* fileextra: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica il retro dello scontrino',
        name: 'fileextra',
        id: 'fileextra',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    }, */
    date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: `${dateLabel}`,
        name: 'date',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today', //mm-dd-yyyy or today
      },
      valid: false,
    },
    time: {
      elementType: 'inputtime',
      elementConfig: {
        type: 'time',
        name: 'time',
        id: 'time',
        placeholder: `${timeLabel}`,
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    number: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'number',
        id: 'number',
        placeholder: `${numberLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: 'price',
      elementConfig: {
        type: 'number',
        name: 'amount',
        id: 'amount',
        placeholder: `${amountLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const contactForm = () => {
  return {
    topic: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: `Seleziona`, selected: true },
          { value: 'Premio', displayValue: 'Premio' },
          { value: 'Gioco', displayValue: 'Gioco' },
          { value: 'Altro', displayValue: 'Altro' },
        ],
        placeholder: `Seleziona`,
        name: 'topic',
      },
      label: 'Scegli un argomento',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: 'textarea',
      elementConfig: {
        name: 'message',
        id: 'message',
        placeholder: 'Scrivi',
      },
      label: 'Il tuo messaggio',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<p>Dichiaro di aver letto e accettato <a href='${process.env.PUBLIC_URL}/pdf/informativa-form-contatti.pdf' target='_blank' rel='noopener noreferrer'>l'informativa privacy</a></p>. `,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = user => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: 'Nome',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: 'Cognome',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.lastname ? true : false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: 'Email',
      value: user.email,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.email ? true : false,
    },
    code: {
      elementType: 'input',
      isCode: true,
      elementConfig: {
        type: 'text',
        name: 'code',
        id: 'code',
        placeholder: 'N° tessera',
      },
      label: 'N° tessera',
      value: user.code || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.code ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: 'Indirizzo',
      value: user.address,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.address ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Provincia', disabled: true }],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: user.district || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.district ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: user.city || '',
            displayValue: user.city || 'città',
            disabled: true,
          },
        ],
        name: 'city',
      },
      label: 'città',
      value: user.city || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.city ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: 'CAP',
      value: user.zipcode || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.zipcode ? true : false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: 'Cellulare',
      value: user.phone,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.phone ? true : false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      lang: 'it',
      label: 'Data di nascita',
      value: moment(user.dob).format('DD/MM/YYYY') || '',
      valuetosend: moment(user.dob).format('L') || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.dob ? true : false,
    },
  };
};

export const firstAccessForm = avatars => {
  return {
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<span>Dichiaro di aver letto e compreso il <a href='${process.env.PUBLIC_URL}/pdf/regolamento.pdf' target='_blank' rel='noopener noreferrer' >Regolamento dell'iniziativa</a></span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },

    privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'privacy',
        id: 'privacy',
      },
      label: `<span>Dichiaro di aver letto e compreso l' <a href='${process.env.PUBLIC_URL}/pdf/INFORMATIVA PRIVACY Gamification.pdf' target='_blank' rel='noopener noreferrer' >Informativa sulla privacy</a></span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    nickname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'nickname',
        id: 'nickname',
        placeholder: 'NickName',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },

    avatar: {
      elementType: 'inputradioavatar',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'avatar',
        id: 'avatar',
      },
      label: ``,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'avatar1',
          label: `City lover`,
          value: 1,
          src: `${avatar1}`,
        },
        {
          id: 'avatar2',
          label: `Mountain lover`,
          value: 2,
          src: `${avatar2}`,
        },
        {
          id: 'avatar3',
          label: `Sea lover`,
          value: 3,
          src: `${avatar3}`,
        },
        {
          id: 'avatar4',
          label: `City lover`,
          value: 4,
          src: `${avatar4}`,
        },
        {
          id: 'avatar5',
          label: `Mountain lover`,
          value: 5,
          src: `${avatar5}`,
        },
        {
          id: 'avatar6',
          label: `Sea lover`,
          value: 6,
          src: `${avatar6}`,
        },
      ],
    },
  };
};

export const concorsoForm = ({ maxDate, pdf }) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
    photo_title: {
      elementType: 'input',
      isInvited_by: true,
      elementConfig: {
        type: 'text',
        name: 'photo_title',
        id: 'photo_title',
        placeholder: '',
      },
      label: 'TITOLO FOTO',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: false,
    },
    photo_date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: '',
        name: 'photo_date',
      },
      label: 'INSERISCI LA DATA DEL MATRIMONIO',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        minDate: new Date(),
        maxDate, //mm-dd-yyyy or today
      },
      valid: false,
    },
    store: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: '',
            disabled: true,
          },
        ],
        placeholder: 'Scegli un atelier',
        name: 'store',
      },
      label: 'SCEGLI UN ATELIER',
      value: '',
      isStore: true,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<span>Dichiarare di non aver ancora acquistato l’abito da sposa per il mio matrimonio</span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'privacy',
        id: 'privacy',
      },
      label: `<span>Dichiaro di aver letto e accettato il <a href=${pdf} target='_blank'>regolamento</a> </span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
