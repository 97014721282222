import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SurveyQuiz } from '../../Interactions';
import { Box, Loader } from '../../../Ui';
import PreInteraction from '../../PreInteraction';
import ResultGame from '../../Response/ResultGame';
import ThankYouPage from '../ThankYouPage/ThankYouPage';
import { Modal } from '../../../Ui';
const SurveyQuizHandler = props => {
  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
    action: { loading, error },
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction);
  const [showThankYouPage, setshowThankYouPage] = useState(false);
  let content = null;

  const onShowResult = () => setShowResult(true);
  const onShowThankYouPage = () => {
    setshowThankYouPage(true);
  };

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  useEffect(() => {
    if (error?.response?.status) {
      setShowErrorModal(true);
    }
  }, [error]);

  const closeErrorModal = () => {
    setShowErrorModal(false);
    window.location.href = '/';
  };

  content = (
    <>
      {loading ? (
        <Loader show={loading} full />
      ) : showResult || played !== null ? (
        <ResultGame {...props} />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      ) : error?.response?.status ? (
        <Modal show={showErrorModal} close={closeErrorModal}>
          Si è verificato un errore, riprova più tardi!
        </Modal>
      ) : (
        <SurveyQuiz
          typeInteraction='interaction'
          onShowThankYouPage={onShowThankYouPage}
          onShowResult={onShowResult}
          {...props}
          infoPages={props.g}
        />
      )}
    </>
  );

  return content;
};

SurveyQuizHandler.propTypes = {
  action: PropTypes.object,
};

export default SurveyQuizHandler;
