import * as actionTypes from './actionTypes';
import {
  getCmsFooter,
  getCmsAuthentication,
  getCmsProfile,
  getCmsContact,
  getCmsHowItWorks,
  getCmsHome,
  getCmsGame,
  getCmsAdvantages,
} from './cms';
import { checkAuth, checkAuthState } from './auth';
import { appInit, oauthInfo } from './app';
import { getPartnerList } from './partners';

const bootAppStart = () => {
  return {
    type: actionTypes.BOOT_APP_START,
  };
};

const bootAppSuccess = () => {
  return {
    type: actionTypes.BOOT_APP_SUCCESS,
  };
};

const bootAppError = error => {
  return {
    type: actionTypes.BOOT_APP_ERROR,
    error,
  };
};

export const bootApp = () => {
  return dispatch => {
    dispatch(bootAppStart());

    Promise.all([
      dispatch(appInit()),
      dispatch(checkAuth()),
      dispatch(checkAuthState()),
      dispatch(getCmsFooter()),
      dispatch(getCmsAuthentication()),
      dispatch(getCmsProfile()),
      dispatch(getCmsContact()),
      dispatch(getCmsHowItWorks()),
      dispatch(getCmsHome()),
      dispatch(getCmsGame()),
      dispatch(getCmsAdvantages()),
    ])
      .then(() => {
        dispatch(bootAppSuccess());
      })
      .catch(error => {
        console.log(error);
        dispatch(bootAppError(error.response.status));
      });
  };
};
