import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Wrapper } from '../../components/Ui';
import { useParams, Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import ErrorInModal from '../../components/ErrorInModal';
import { Modal, Flex, Col, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import { ReactComponent as Storico } from '../../assets/images/icon_storico.svg';
import { ReactComponent as Letter } from '../../assets/images/icon_email.svg';
import ContentSlide from '../../components/ContentSlide';
import { Code } from 'styled-icons/material';
import CopyButton from '../../components/Games/Response/Code/DisplayCode/CopyButton/CopyButton';
import BoxLayout from '../../components/Ui/BoxLayout';
import { numberWithDots } from '../../utils/utils';

const SinglePremio = props => {
  const [hasCoupon, setHasCoupon] = useState(null);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [requestLoading, setLoadingRequest] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [partner, setPartner] = useState();
  const [code, setCode] = useState(null);
  const [modalRequestOpened, setModalRequestOpened] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { name, description, static_code, main_image, value, progress } = useSelector(
    state => state.partners.partnerList?.find(item => +item.id === +id) || {},
  );
  const userPoints = useSelector(state => state.user.user.points);

  const partners = useSelector(state => state.partners.partnerList);
  const vouchers = useSelector(state => state.user.user.vouchers);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (id && partners) {
      const newPartner = partners.find(partner => partner.id === Number(id));
      setPartner(newPartner);
    }
  }, [partners, id]);

  useEffect(() => {
    if (partner && vouchers) {
      const existCoupon = vouchers.find(voucher => voucher.partner_id === partner.id);

      if (existCoupon) {
        setHasCoupon(existCoupon);
      }
    }
  }, [partner, vouchers]);
  const closeModalRequest = () => {
    setModalRequestOpened(false);
  };
  const closeModalError = () => {
    setModalError(false);
  };
  const requestCouponHandler = code => {
    let endpoint = `partners/${partner.id}/request`;
    setModalRequestOpened(false);
    setLoadingRequest(true);
    customaxios
      .post(endpoint)
      .then(result => {
        setLoadingRequest(false);
        dispatch(actionCreators.getUserInfo());
        setRequestCompleted(true);
        setCode(result.data.code);
      })
      .catch(error => {
        setLoadingRequest(false);
        setModalError(error.response.data.error);
      });
  };

  return (
    <>
      <Fade>
        <Wrapper>
          <CustomFlex
            row={15}
            widthRequest={userPoints?.points_confirmed >= value}
            progressNum={progress}
          >
            <Col width={50} className='col1'>
              <img src={main_image} alt='' className='premio-img' />
            </Col>
            <Col width={50} className='col2'>
              <Flex direction='column' style={{ width: '100%' }}>
                <Flex>
                  <Text as='p' size={12}>
                    codice:
                  </Text>
                  <Text size={12} primary style={{ marginLeft: '5px' }}>
                    {static_code}
                  </Text>
                </Flex>

                <ContentSlide
                  title={name}
                  text={description}
                  size={30}
                  xBold='800'
                  noBtn
                  style={{ flex: 1 }}
                />
                <Link to='/premi' className='col2-link'>
                  <Button arrowLeft hoverGrey border fullwidth className='col2-btn' upper size={12}>
                    Indietro
                  </Button>
                </Link>
              </Flex>
            </Col>
          </CustomFlex>
        </Wrapper>
      </Fade>
      <Modal show={modalRequestOpened} close={closeModalRequest}>
        <Text as='p' size={18} bold margin='3px 0 25px 0' align='left'>
          Confermi di voler richiedere il premio selezionato?
        </Text>

        <Button
          active
          style={{ display: 'block', textAlign: 'left', marginLeft: 'auto' }}
          loading={requestLoading}
          disabled={requestLoading}
          onClick={() => requestCouponHandler(partner.static_code)}
        >
          CONFERMA
        </Button>
      </Modal>
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>

      {code && requestCompleted && (
        <Wrapper>
          <BoxLayout
            width='100%'
            title='COME USARLO'
            noMinHeight
            style={{ width: 'calc(100% + 30px)', margin: '20px -15px 50px -15px' }}
          >
            <Text size={14} margin='0 0 30px 0'>
              Per utilizzare il tuo buono upgrade, ti è sufficiente contattare la tua Agenzia di
              Viaggi o i Call Center Alpitour World, prenotare la vacanza che hai scelto e
              comunicare l'upgrade che intendi utilizzare. Penseranno a tutto loro.
            </Text>
            <Flex gap='30px'>
              <Flex gap='10px' align='center'>
                <Storico style={{ width: '40px' }} />
                <a href='https://www.alpitour.it/trova-agenzie.html' target='_blank'>
                  <Text as='p' size={11} style={{ color: '#AAAAA3' }}>
                    STORE LOCATOR
                  </Text>
                </a>
              </Flex>
              <Flex gap='10px' align='center'>
                <Letter style={{ width: '40px' }} />
                <a href='mailto:youandsun@alpitourworld.it'>
                  <Text as='p' size={11} style={{ color: '#AAAAA3' }}>
                    YOUANDSUN@ALPITOURWORLD.IT
                  </Text>
                </a>
              </Flex>
            </Flex>
          </BoxLayout>
        </Wrapper>
      )}
    </>
  );
};
const CustomFlex = styled(Flex)`
  margin-bottom: 60px;

  .col1 {
    position: relative;
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 100px;
  }
  .col2 {
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
    ${respondTo.sm`
        padding: 10px 10px 10px 30px;
       background-color:initial;
       margin-top:130px;
    `}
    &-link {
      width: 100%;
      ${respondTo.sm`
        width:initial;
    `}
    }
    &-btn {
      justify-content: space-between;
      svg {
        order: -1;
        right: 0;
      }
    }
  }

  .premio-img {
    width: 100%;
    height: 400px;
    display: block;
    object-fit: cover;

    ${respondTo.sm`
      height: 600px;
    `}
  }

  .request {
    background-color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 10px 0;
    border-radius: 15px;
    right: 10px;

    ${respondTo.sm`
        right: ${({ widthRequest }) => (widthRequest ? '10px' : 'auto')};
    `}
    &-col1 {
      ${respondTo.sm`
        width:100%;
    `}
      @media(min-width:1100px) {
        width: initial;
      }
    }
    &-col2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -15px 0;
      border-left: 1px solid ${({ theme }) => theme.bgcode};
      ${respondTo.sm`
        width:100%;
    `}
      @media(min-width:1100px) {
        width: 30%;
      }
    }
  }
  .progress {
    width: 100%;
    height: 16px;
    background-color: #f1f1f1;
    position: relative;
    border-radius: 5px;
    &-width {
      width: ${({ progressNum }) => progressNum + '%'};
      height: 100%;
      border-radius: inherit;
      background-color: #002d7280;
    }
    &-num {
      color: ${({ theme }) => theme.primary};
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  .percent {
    color: ${({ theme }) => theme.primary};
    margin: 0 3px;
  }
  .coupon-code {
    width: 50%;
    background-color: ${({ theme }) => theme.bgColorDiv};
    padding: 3px 10px;
    margin: 0 auto;

    @media (min-width: 1100px) {
      width: 100%;
    }
  }
  .hiw-content {
    padding: 0;
    margin-top: 20px;
  }
  .hiw-text {
    width: 100%;
  }
`;

export default SinglePremio;
