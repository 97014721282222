import React from 'react';
import Banner from '../../components/banner';
import ContentSlide from '../../components/ContentSlide';
import Fade from '../../components/Fade';
import { Box, Button, Flex, Text, Wrapper } from '../../components/Ui';
import img from '../../assets/images/beachGame.jpeg';
import icon from '../../assets/images/travelGame.svg';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
function PremiPalio() {
  const lastLocation = useLastLocation();
  const content1 = (
    <>
      <Text size={16} bold style={{ marginTop: '50px' }}>
        Tutti gli iscritti al programma “Gamification 2022-2025” potranno partecipare al concorso in
        modalità IW dal 03/06/2022 al 11/11/2022. Ogni partecipante avrà a disposizione una chance
        di gioco utilizzabile ogni venerdì dalle 14:00 alle 22:00 in palio: 14 viaggi Alpitour World
        di “corto raggio”, 6 viaggi Alpitour World di “medio raggio” e 4 viaggi Alpitour World di
        “lungo raggio”.
      </Text>
      <br />
      <br />
      {/* sunny-friday */}
      <Text size={16} bold>
        Ciascun viaggio è valido per 2 persone, è usufruibile dalla data di convalida fino a 180
        giorni dalla fine del programma (esclusi ponti e festività) ed è costituito da:
      </Text>
      <ul className='list'>
        <li>biglietto aereo a/r da e per l’Italia (bagaglio a mano e in stiva incluso) </li>
        <li> tasse e oneri aeroportuali</li>
        <li>adeguamento carburante e valutario </li>
        <li>
          7 notti in Hotel in camera doppia standard con colazione inclusa e altri servizi in base
          alla struttura.
        </li>
        <li> trasferimento Aeroporto - Hotel e Aeroporto</li>
        <li> assicurazione medico bagaglio di base </li>
      </ul>
      <br />
      <br />
      <Text size={16} bold>
        I vincitori, in relazione al premio vinto, potranno scegliere tra le destinazioni
        disponibili in base alle date desiderate e/o una data tra le disponibilità per una specifica
        destinazione. Il premio verrà erogato tenendo conto della disponibilità e della
        stagionalità. Le possibili destinazioni saranno:
      </Text>
      <br />
      <br />
      <Text size={16} bold>
        CORTO RAGGIO: Andalusia, Baleari, Italia, Marocco, Portogallo, Tunisia, Karpathos, Kos,
        Creta, Mykonos, Rodi.
      </Text>
      <Text size={16} bold>
        MEDIO RAGGIO: Canarie, Capo Verde, Egitto, Oman.
      </Text>
      <Text size={16} bold>
        LUNGO RAGGIO: Kenya, Madagascar, Zanzibar, Cuba, Maldive, Giamaica, Messico, Mauritius,
        Repubblica Dominicana.
      </Text>
    </>
  );
  const content2 = (
    <>
      <Text size={16}>
        Tutti gli iscritti al programma “Gamification 2022-2025” potranno partecipare al concorso in
        modalità IW dal 17/06/2022 al 25/11/2022. Ogni partecipante avrà a disposizione una chance
        di gioco utilizzabile ogni venerdì, in palio 24 viaggi Alpitour World.
        <br />
        <br />
        Ciascun viaggio è valido per 2 persone, è usufruibile dalla data di convalida fino a 180
        giorni dalla fine del programma (esclusi ponti e festività) ed è costituito da:
      </Text>
      <ul className='list'>
        <li>biglietto aereo a/r da e per l’Italia (bagaglio a mano e in stiva incluso)</li>
        <li>tasse e oneri aeroportuali</li>
        <li>adeguamento carburante e valutario</li>
        <li>
          n. 7 notti in Hotel in camera doppia standard con colazione inclusa e altri servizi in
          base alla struttura di riferimento.
        </li>
        <li> Trasferimento Aeroporto - Hotel - Aeroporto</li>
        <li> assicurazione medico bagaglio di base </li>
      </ul>
      <br />
      <Text size={16}>
        Ogni settimana sarà pubblicata la località in palio per quel venerdì. Il premio verrà
        erogato tenendo conto della disponibilità e della stagionalità.
      </Text>
    </>
  );
  return (
    <Fade>
      <Wrapper>
        <Banner>
          <HiwBanner>
            <ContentSlide
              title='I premi in palio'
              text='Ogni venerdì prova a vincere la tua prossima vacanza.'
              img={img}
              icon={icon}
              noBtn
              linear
              size={30}
              xBold='800'
              textBold
            />
          </HiwBanner>
        </Banner>
        <CustomBox align='stretch' justify='center'>
          {content2}
          <Flex justify='space-between' align='center' style={{ marginTop: '50px' }}>
            <a
              href={`${process.env.PUBLIC_URL}/pdf/regolamento_sunny_friday.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button active className='btn-scarica' hover>
                SCARICA IL REGOLAMENTO
              </Button>
            </a>
            <Flex align='center' gap='20px'>
              <Link to='/contact'>
                <Button border className='btn-aiuto' hoverGrey>
                  SERVE AIUTO?
                </Button>
              </Link>
              <Link to={lastLocation?.pathname}>
                <Button border className='btn-aiuto' hoverGrey>
                  INDIETRO
                </Button>
              </Link>
            </Flex>
          </Flex>
        </CustomBox>
      </Wrapper>
    </Fade>
  );
}
const HiwBanner = styled.div``;
const CustomBox = styled(Box)`
  margin-bottom: 30px;
  z-index: 5;
  position: relative;
  ${respondTo.sm` 
      margin-top: -60px;
    `};
  .list {
    margin-left: 20px;
    color: ${({ theme }) => theme.default_color};
    list-style-type: '- ';
  }
`;

export default PremiPalio;
