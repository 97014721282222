import React, { Suspense, useState, useEffect } from 'react';
import { Button, Loader, Modal, Text } from '../../components/Ui';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SessionExpired from '../../components/SessionExpired';
import { connect, useSelector, useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { clearFirstAccessError } from '../../store/actions/auth';
import img from '../../assets/images/bgGlobal1.jpg';
import { Link, useLocation } from 'react-router-dom';
import { getFormInfo } from '../../store/actions/user';

const MainContent = styled.div`
  flex: 1 0 auto;
  //max-width: 1200px;
  width: 100%;
  // margin: 0 auto;
  //padding: ${props => props.theme.header_mobile_height + 30}px 15px 0 15px;
  //padding: 0 15px 0 15px;

  ${respondTo.sm`
		padding-top: 0;
	`}
`;
const ContainerGlobal = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 570px;
`;

const BaseLayout = props => {
  const dispatch = useDispatch();
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    appConfig: { i18l },
    firstAccess,
    footer_brand_bar,
  } = props;

  const errorFirstAccess = useSelector(state => state.auth.errorFirstAccess);

  const [accessErrorModal, setAccessErrorModal] = useState(false);
  const wins = useSelector(state => state.user.user?.wins);
  const [isModal, setIsModal] = useState(false);

  const location = useLocation();
  const [winState, setWinState] = useState(true);

  useEffect(() => {
    if (winState) {
      if (wins?.length > 0) {
        if (location.pathname !== '/form-win') {
          if (!/game\/\d+$/.test(location.pathname)) {
            const listWins = wins?.filter(item => item.pending);
            setIsModal(listWins[0]?.pending);
            setWinState(false);
          }
        }
      }
    }
  }, [wins]);

  useEffect(() => {
    if (userInfo && userInfo.expire_date_limit) {
      const today = new Date();

      if (Date.parse(today) > Date.parse(userInfo.expire_date_limit.replaceAll('-', '/'))) {
        props.history.push('/account-deleted');
      }
    }
  }, [props.history, userInfo]);

  useEffect(() => {
    if (errorFirstAccess) {
      setAccessErrorModal(errorFirstAccess);
    }
  }, [errorFirstAccess]);

  useEffect(() => {
    if (firstAccess !== undefined && !firstAccess && location.pathname !== '/cookie-policy') {
      props.history.push('/first-access');
    }
  }, [firstAccess, props.history]);

  let lazy = null;

  const clickHandler = () => {
    if (wins?.length > 0 && location.pathname !== '/form-win') {
      const listWins = wins.filter(item => item.pending);
      listWins.length > 0 && dispatch(getFormInfo(listWins[0]?.game_id, listWins[0]?.id));
    }
    setIsModal(false);
  };
  const Content = (
    <ContainerGlobal>
      <MainContent>
        <Header
          isLogged={isLogged}
          logo={logo}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          logout={logout}
          bootApp={bootApp}
          langs={langs}
          oauths={oauths}
          ownLogin={ownLogin}
          multipromo={multipromo}
          oauthInfo={oauthInfo}
          userInfo={userInfo}
          oauth_profile_url={oauth_profile_url}
          i18l={i18l}
        />

        <main>
          <C isLogged={isLogged} />
        </main>
      </MainContent>

      <Footer
        footer_brand_bar={footer_brand_bar}
        footercontent={footercontent}
        inHome={location.pathname === '/'}
      />

      <Modal
        show={accessErrorModal}
        close={() => {
          setAccessErrorModal(false);
          dispatch(clearFirstAccessError());
        }}
      >
        {accessErrorModal}
      </Modal>
      <Modal show={isModal} close={() => setIsModal(false)}>
        <Text>Complimenti, Hai un premio che ti aspetta!</Text>
        <Link to='/form-win'>
          <Button active hover onClick={clickHandler} className='mt-20' bold>
            Clicca qui
          </Button>
        </Link>
      </Modal>
    </ContainerGlobal>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: 'base',
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    logoinverted: state.app.config.logoinverted,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    firstAccess: state.user.user.first_access_done,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
