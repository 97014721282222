import React, { useEffect, useState } from 'react';
import { Flex, Button, Text, Logo, Wrapper } from '../../components/Ui';
import { Link } from 'react-router-dom';
import Fade from '../../components/Fade/Fade';
import { connect } from 'react-redux';
import { getSlug } from '../../utils/utils';
import Seo from '../../components/Seo';
import * as actionCreators from '../../store/actions';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { TravelCouponsModule, YourSituationModule } from '../../components/HomeModules';
import axios from 'axios';
import CustomSlider from '../../components/customSlider';
import ContentSlide from '../../components/ContentSlide';
import QuizGame from '../../components/QuizGame';
import ClassificaGenerale from '../../components/ClassificaGenerale';
import img1 from '../../assets/images/alpitour1.png';
import img2 from '../../assets/images/beach2.jpeg';
import img3 from '../../assets/images/beach3.jpeg';
import img from '../../assets/images/iconTravel.svg';
import Contact from '../../components/contact';
import TuaCard from '../../components/TuaCard';
import PremiSlider from '../../components/PremiSlider';
import Banner from '../../components/banner';
import imgMobile from '../../assets/images/homeMobile.png';
const Initiative = props => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    promoinfo,
    getPromoViewer,
    viewer,
    getCmsIntro,
    games,
    getPromoInfo,
    error404,
    howItWorksCms,
    homeCms,
    isLogged,
    logo,
  } = props;

  let slug = 'get';

  if (multipromo) {
    slug = getSlug();
  }

  let seo = null;
  let idgame = null;

  if (games.length > 0 && games.length === 1) {
    idgame = games[0].id;
  }

  if (Object.entries(promoinfo).length !== 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = promoinfo;
    seo = (
      <Seo title={title} description={description} keywords={keywords} image={viewer.img_mobile} />
    );
  }

  useEffect(() => {
    localStorage.setItem('lasturl', window.location.href);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  const how_image = homeCms[0]?.contents?.find(e => e.name === 'home_hiw_image');
  const how_text = homeCms[0]?.contents?.find(e => e.name === 'home_hiw_text');
  const listSlider = [
    {
      title: 'Con Gamification sei sempre in vacanza',
      content:
        'Partecipare al programma è semplice, più parti e più vieni premiato! Prenota uno o più viaggi e accumula punti per la tua prossima vacanza.',
      img: img1,
    },
    /* {
      title: 'Con Gamification sei sempre in vacanza',
      content:
        'Partecipare al programma è semplice, più parti e più vieni premiato! Dal 01/04/2022 e fino al 31/03/2025 prenota uno o più viaggi e accumula punti per la tua prossima vacanza.',
      img: img2,
    },
    {
      title: 'Con Gamification sei sempre in vacanza',
      content:
        'Partecipare al programma è semplice, più parti e più vieni premiato! Dal 01/04/2022 e fino al 31/03/2025 prenota uno o più viaggi e accumula punti per la tua prossima vacanza.',
      img: img3,
    }, */
  ];
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'custom-slider',
    infinite: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    dotsClass: 'slider-dots',
  };

  return (
    <Fade>
      <Wrapper>
        <Banner>
          <CustomSlider settings={settings}>
            {listSlider.map((item, i) => (
              <ContentSlide
                key={i}
                title={item.title}
                text={item.content}
                img={item.img}
                logo
                textBold
                link='/howitworks'
                scopri
                imgMobile={imgMobile}
              />
            ))}
          </CustomSlider>
        </Banner>

        {/* <YourSituationModule /> */}
        <CustomFlex
          align='stretch'
          gap='15px'
          justify='center'
          widthQuizGame={isLogged ? '60%' : '100%'}
        >
          {/* <div className='travelGames'>
            <CustomSlider settings={settings}>
              <QuizGame title='CONCORSI' img={img} lancio height='100%' noBoxShadow />
              <QuizGame title='travel game' img={img} lancio height='100%' noBoxShadow />
            </CustomSlider>
          </div> */}
          <QuizGame title='travel game' img={img} width={isLogged ? '60%' : '60%'} lancio />
          <PremiSlider width={isLogged ? '38%' : '38%'} />
          {isLogged && <ClassificaGenerale />}
          {isLogged && <TuaCard />}
        </CustomFlex>
      </Wrapper>
    </Fade>
  );
};

const CTA = styled.div`
  background-color: #f1f3f7;
  padding: 15px;
  margin-bottom: 50px;
  span {
    margin-right: 30px;
  }
`;
const CustomFlex = styled(Flex)`
  margin-bottom: 70px;
  background-color: transparent;
  & > div {
    min-height: 420px;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
  .travelGames {
    box-shadow: ${({ theme }) => theme.shadow};
    width: 100%;
    position: relative;
    border-radius: 20px;
    ${respondTo.sm` 
       width: ${({ widthQuizGame }) => widthQuizGame};
    `};
    .slick-list {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
    .slick-slider.custom-slider {
      position: static;
      height: 100%;
    }
    .slider-dots {
      position: absolute;
      bottom: 10px;
      left: 10px;
      list-style-type: none;
      display: flex !important;
      ${respondTo.sm` 
     
    `};
      li {
        button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-size: 0;
          color: inherit;

          padding: 0;
          cursor: pointer;
          outline: inherit;
          border: 3px solid ${({ theme }) => theme.bgcode};
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: transparent;
          margin-right: 7px;
          border-radius: 50%;
        }

        &.slick-active button {
          transition: 1s;
          background-color: grey;
          border-radius: 50px;
          width: 25px;
          border: none;
        }
      }
    }
  }
`;

const mapStateToProps = state => {
  return {
    logo: state.app.config.logo,
    appConfig: state.app.config,
    promoinfo: state.promo.promoinfo,
    viewer: state.promo.viewer,
    games: state.game.games,
    error404: state.promo.error404,
    howItWorksCms: state.cms.howItWorks,
    homeCms: state.cms.home,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPromoViewer: slug => dispatch(actionCreators.getPromoViewer(slug)),
    getCmsIntro: slug => dispatch(actionCreators.getCmsIntro(slug)),
    getPromoInfo: (slug, cid) => dispatch(actionCreators.getPromoInfo(slug, cid)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Initiative));
