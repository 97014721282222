import React, { useState } from 'react';
import styled from 'styled-components';
import icon from '../../assets/images/iconGuadagnarePoint.svg';
import img1 from '../../assets/images/icon_guadagnare.svg';
import img2 from '../../assets/images/iconComplementeProfile.svg';
import img3 from '../../assets/images/iconTravel.svg';
import img4 from '../../assets/images/icon_file.svg';
import bgSection from '../../assets/images/section2Desk.png';
import { Button, Text } from '../Ui';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../Ui/BoxLayout';
import CustomSlider from '../customSlider';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CouponBox from '../CouponBox/CouponBox';

const Content = styled.div`
  .content {
    margin: 10px 0;
    width: 100%;
    //height: 280px;
    background-color: ${({ theme }) => theme.bgColorDiv};
    border-radius: 15px;
    padding: 60px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 22px;

    img {
      display: block;
      width: 110px;
      height: 110px;
    }
    .content-text {
      flex: 1;
      width: 100%;
      .content-title {
        font-size: 18px;
        ${respondTo.sm` 
      font-size:14px;
     `};
        ${respondTo.md` 
      font-size:18px;
     `};
      }
    }
    .points {
      background-color: ${({ theme }) => theme.default_colortext};
      width: 68%;
      text-align: center;
      border-radius: 10px;
      padding: 5px;
      margin-top: 10px;
    }
  }
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .slick-slider.custom-slider {
    position: static;
  }
  .slider-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    list-style-type: none;
    display: flex !important;
    ${respondTo.sm` 
     
    `};
    li {
      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 0;
        color: inherit;

        padding: 0;
        cursor: pointer;
        outline: inherit;
        border: 3px solid ${({ theme }) => theme.bgcode};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: transparent;
        margin-right: 7px;
        border-radius: 50%;
      }

      &.slick-active button {
        transition: 1s;
        background-color: grey;
        border-radius: 50px;
        width: 25px;
        border: none;
      }
    }
  }
`;

function PremiSlider({ width }) {
  const token = useSelector(state => state.auth?.token);

  const { partnerList } = useSelector(state => state.partners);
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'custom-slider',
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    adaptiveHeight: true,
    dotsClass: 'slider-dots',
  };
  return (
    <BoxLayout title='I PREMI' icon={icon} width={width} /* bg2={bgSection} */>
      <Container>
        <Text size='14' capit>
          Scopri tutte le azioni che ti permettono di accumulare punti. La prossima vacanza non è
          mai stata così vicina!
        </Text>
        <CustomSlider settings={settings}>
          {partnerList.map((item, key) => (
            <Content key={key}>
              <CouponBox key={item.id} partner={item} />
            </Content>
          ))}
        </CustomSlider>

        {/* {token ? (
          <Link to='/howitworks/howtoearn'>
            <Button active upper size='11px' hover style={{ marginLeft: 'auto' }}>
              SCOPRILI TUTTI
            </Button>
          </Link>
        ) : (
          <a href={process.env.REACT_APP_COGNITO_LOGIN}>
            <Button active upper size='11px' hover style={{ marginLeft: 'auto' }}>
              SCOPRILI TUTTI
            </Button>
          </a>
        )} */}
      </Container>
    </BoxLayout>
  );
}

export default PremiSlider;
