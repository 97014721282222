import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { respondTo } from '../../../../theme/mixin';

const LinkLi = styled.div`
  display: block;

  ${respondTo.md`
      display: ${props => (props.onlyMobile ? 'none' : 'block')};
  `};
`;

const LinkElem = styled(NavLink)`
  display: block;
  padding: 15px 0;

  ${respondTo.md`
    text-decoration: none;
    margin-right: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0;
  `};

  span {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid transparent;
    transition: 0.3s;
    border-radius: 5px;
    padding: 0 5px;
    ${respondTo.md`
      font-size: 12px;
    `};
  }

  &.active,
  &:hover {
    span {
      color: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
    }
  }
`;

const NavigationItem = props => {
  const { link, children, onlyMobile } = props;

  return (
    <LinkLi onlyMobile={onlyMobile}>
      <LinkElem exact to={link} {...props}>
        {children}
      </LinkElem>
    </LinkLi>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

export default NavigationItem;
