import React from 'react';
import styled from 'styled-components';
import Flex from '../../Flex/Flex';
import { respondTo } from '../../../../theme/mixin';
import { useState } from 'react';

const Container = styled.div`
  label {
    display: block;
    position: relative;
    padding: 12px 16px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 20px;
    background: ${props => props.theme.bgColorDiv};
    border-radius: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    ${respondTo.sm`
      width: calc(100% / 2 - 8px);
  `}
    ${respondTo.md`
      width: calc(100% /3 - 8px);
  `}
    &.checkmark {
      border: 1px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : 'transparent'};
    }
    &.selected {
      background: #6d818e;
      color: white;
      & .sm-span {
        color: white;
      }
    }
    & .sm-span {
      text-transform: uppercase;
      font-size: 12px;
      color: ${props => props.theme.text_grey};
    }
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

const RadioAvatar = props => {
  return (
    <Container onChange={props.onChange} {...props}>
      <Flex justify='space-between' gap='16'>
        {props.radioInputs.map((radio, indx) => {
          return (
            <label
              htmlFor={radio.id}
              key={indx}
              className={`${+radio.value === +props.selectedAvatar ? 'selected' : ''} checkmark`}
            >
              <img src={radio.src} alt='' />
              <div>
                <span className='sm-span'>STILE</span>
                <br />
                <span>{radio.label}</span>
              </div>

              <input
                {...props.elementConfig}
                id={radio.id}
                value={radio.value}
                checked={radio.value === props.value}
              />
            </label>
          );
        })}
      </Flex>
    </Container>
  );
};

export default RadioAvatar;
