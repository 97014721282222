import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { Text } from '../../Ui';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import Logout from '../../Logout/Logout';
import LinkDownload from './NavigationItem/LinkDownload/LinkDownload';
import { Link } from 'react-router-dom';

const NavUl = styled.ul`
  list-style: none;

  .last-child {
    margin-right: 0;
  }

  ${respondTo.md`
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
	`}

  .only-desktop {
    display: none;

    ${respondTo.md`
      display: block;
    `}
  }

  .only-mobile {
    display: block;

    &.logout {
      border-top: 1px solid #dce4e8;
      padding: 15px 0;
    }

    ${respondTo.md`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const { isLogged, multipromo, i18l, idGame, isOpen, logout, webview } = props;

  const [t] = useTranslation();

  return (
    <NavUl isOpen={isOpen} onClick={props.clicked}>
      {isLogged && (
        <>
          <NavigationItem link='/howitworks' active='true'>
            <Text bold size={12}>
              come funziona
            </Text>
          </NavigationItem>

          <NavigationItem link='/premi' active='true'>
            <Text bold size={12}>
              premi
            </Text>
          </NavigationItem>

          <NavigationItem link='/user/profile' active='true'>
            <Text bold size={12}>
              {t('header.profile')}
            </Text>
          </NavigationItem>

          <NavigationItem link='/concorsi' active='true'>
            <Text bold size={12}>
              Quiz
            </Text>
          </NavigationItem>

          <div className='only-mobile'>
            <NavigationItem link={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`} target='_blank'>
              <Text bold size={12}>
                Regolamento Gamification
              </Text>
            </NavigationItem>
          </div>

          <div className='only-desktop'>
            <LinkDownload />
          </div>

          <NavigationItem link='/contact' active='true' className='last-child'>
            <Text bold size={12}>
              {t('header.contact')}
            </Text>
          </NavigationItem>

          {!webview && (
            <Link to='/'>
              <div className='only-mobile logout' onClick={logout}>
                <Logout label={t('auth.logoutlabel')} />
              </div>
            </Link>
          )}
        </>
      )}
      {i18l.nav.menu_static_links
        ? i18l.nav.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
