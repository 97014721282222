import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { respondTo } from '../../../theme/mixin';
import Button from '../Button';
import Text from '../Text';

const Cont = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 100px 15px;
  border-radius: 16px;
  box-shadow: 0px 2px 15px #00000029;
`;

const Cont2 = styled.div`
  flex: 1;
  text-align: center;
`;

const TextContainer = styled.div`
  margin-bottom: 22px;
  ${respondTo.sm`
		margin-bottom: 30px;
	`}
`;

const StartGame = props => {
  const { clicked } = props;
  const [t] = useTranslation();

  return (
    <Cont>
      <Cont2>
        <TextContainer>
          <Text as='p' size={30} bold align='center' color='#6D818E'>
            Sei pronto?
          </Text>
        </TextContainer>
        <Button active onClick={clicked} style={{ padding: '0 30px', margin: '0 auto' }}>
          {t('games.startgame')}
        </Button>
      </Cont2>
    </Cont>
  );
};

export default StartGame;
