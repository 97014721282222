import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch, useHistory, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Modal } from '../components/Ui';
import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';
import customAxios from '../config/axios-refresh-token';
import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import NotFound from '../views/NotFound';
import HowItWorks from '../views/HowItWorks/HowItWorks';
import Premi from '../views/Premi';
import FirstAccess from '../views/FirstAccess/FirstAccess';
import AccountDeleted from '../views/AccountDeleted/AccountDeleted';
import Practices from '../views/Practices/Practices';
import HowToEarn from '../views/HowToEarn';
import SinglePremio from '../views/SinglePremio';
import PremiPalio from '../views/PremiPalio/PremiPalio';
import FormWin from '../views/FormWin/FormWin';
import CookiePolicy from '../views/CookiePolicy/CookiePolicy';
import SunnyFriday from '../views/SunnyFriday/SunnyFriday';
import ThankYou from '../views/ThankYou/ThankYou';
import Game from '../views/Game/Game';
import InitiativeAlpi360 from '../views/InitiativeAlpi360';
import Gallery from '../views/Gallery/Gallery';

const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const Profile = React.lazy(() => import('../views/Profile'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));
const Concorsi = React.lazy(() => import('../views/Concorsi'));
const SingleConcorsi = React.lazy(() => import('../views/SingleConcorsi'));
const ConcorsiGame = React.lazy(() => import('../views/ConcorsiGame'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          (window.location = 'https://www.easybook.it/')
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin, isFriday, wins, firstAccessComplete } = props;

  const [hasWin, setHasWin] = useState(false);
  const { end } = useSelector(
    state =>
      state.promo.allpromo?.active?.find(item => item.slug === 'sunny-friday')?.settings || {},
  );
  const [showNewRegulationModal, setshowNewRegulationModal] = useState(false);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (!user.regulation_view && user.first_access_done) {
      setshowNewRegulationModal(true);
      customAxios
        .post(`/auth/me/regulation_view`)
        .then(result => {})
        .catch(error => {
          console.log(error);
        });
    } else {
      setshowNewRegulationModal(false);
    }
  }, [user]);

  const closeModalRegulation = function () {
    setshowNewRegulationModal(false);
  };
  return (
    <Router history={history}>
      {showNewRegulationModal ? (
        <Modal show={showNewRegulationModal} close={() => closeModalRegulation()}>
          <p>
            Gentile utente, il programma Gamification ti premia sempre di più! Abbiamo apportato
            alcune modifiche migliorative al regolamento. Puoi consultare il Regolamento integrale
            modificato a questo{' '}
            <Link
              target={'_blank'}
              to={process.env.PUBLIC_URL + '/pdf/regolamento.pdf'}
              onClick={() => closeModalRegulation()}
            >
              link
            </Link>
            .
          </p>
        </Modal>
      ) : null}
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path='/'
            exact
            component={Initiative}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/first-access'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={FirstAccess}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          {firstAccessComplete && (
            <PrivateRoute
              path='/first-access-done'
              exact
              layoutCmp={AuthLayout}
              isLogged={props.isLogged}
              component={ThankYou}
              ownLogin={ownLogin}
              footer_brand_bar
            />
          )}
          (
          <PrivateRoute
            path='/form-win'
            exact
            component={FormWin}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          )
          <PrivateRoute
            path='/account-deleted'
            exact
            layoutCmp={AuthLayout}
            isLogged={props.isLogged}
            component={AccountDeleted}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/howitworks'
            exact
            component={HowItWorks}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/howitworks/howtoearn'
            exact
            component={HowToEarn}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/premi'
            exact
            component={Premi}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          {isFriday && (
            <PrivateRoute
              path='/sunnyFriday'
              exact
              component={SunnyFriday}
              layoutCmp={BaseLayout}
              isLogged={props.isLogged}
              ownLogin={ownLogin}
              footer_brand_bar
            />
          )}
          <PrivateRoute
            path='/premiPalio'
            exact
            component={PremiPalio}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/concorsi'
            exact
            component={Concorsi}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/concorsi/:slug'
            exact
            component={SingleConcorsi}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/concorsi/:slug/gallery'
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Gallery}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/concorsi/:slug/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/viaggi/:id'
            exact
            component={SinglePremio}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/user/practices'
            exact
            component={Practices}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <Route
            path='/cookie-policy'
            exact
            render={route => <BaseLayout cmp={CookiePolicy} {...route} />}
          />
          <Route path='/login/:token' render={route => <AuthLayout cmp={Login} {...route} />} />
          <Route render={() => <BaseLayout cmp={NotFound} />} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    isFriday: state.app.isFriday,
    wins: state.user.user?.wins,
    firstAccessComplete: state.user.firstAccessComplete,
  };
};

export default connect(mapStateToProps)(Routes);
