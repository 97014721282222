import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { respondTo } from '../../../../theme/mixin.js';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import Fade from '../../../Fade/Fade';
import styled from 'styled-components/macro';
import bggame from '../../../../assets/images/bggame.jpg';
import { ReactComponent as HandOpen } from '../../../../assets/images/handOpen.svg';
import iconParticipe from '../../../../assets/images/boxGame.png';
import { Box, Button, Col, Flex, Loader, Modal, Text } from '../../../Ui';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import customAxios from '../../../../config/axios-refresh-token.js';
import { getUserInfo } from '../../../../store/actions/user.js';
import BoxLayout from '../../../Ui/BoxLayout/index.js';
import { Link } from 'react-router-dom';
import { acceptGameRules } from '../../../../store/actions/game.js';
import { getGame } from '../../../../store/actions/promo.js';
import NotFound from '../../../../views/NotFound/NotFound.js';
const Container = styled.div`
  // height: 100%;
  /* 
  background-image: ${props => (props.bggame ? `url(${props.bggame})` : `url(${bggame})`)};
  background-position: center center;
  background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
  background-size: cover;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
 
  display: flex;
  align-items: center;
  justify-content: center; */
  .title-participe {
    margin: 0px 15px 0 15px;
    ${respondTo.sm` 
    margin: 60px 15px 0 15px;
    `};
  }
  .content-participe {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
  .overlay {
    position: absolute;
    margin-top: 44px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff99;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    padding: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    ${respondTo.sm` 
    margin-top: 70px;
    padding: 20px;
    width: 100px;
    height: 100px;
    `};
  }
  ${respondTo.sm` 
      margin-top: -60px;
  `};
`;

const TextParent = styled.div`
  text-align: center;
`;
const TextContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  ${respondTo.sm`
	font-size: 22px;
	margin-left: 10px;
  `}
`;

const WrapperGame = styled.div`
  max-width: 960px;
  margin: 36px auto;
  max-width: 96%;
  text-align: center;
  ${respondTo.med`
		max-width: 90%;
	`}
  ${respondTo.sm`
		max-width: 760px;
	`}
	${respondTo.md`
		max-width: 830px;
	`}
`;
const OverlayRegola = styled.div``;

const Click = props => {
  const {
    onShowResult,
    lang,
    typeInteraction,
    gameinpage,
    g: {
      played,
      id,
      interaction,
      preinteraction,
      gamePrivacyAccepted,

      already_won,
      stop_attempts_after_win,
      log: { sid },
      settings: { option },
      i18l: {
        info: { title, content },
      },
    },
    action: { data: dataAction, loading },
    regolamento,
    remaining_attempts,
  } = props;

  const dispatch = useDispatch();
  const { games } = useSelector(state => state.game || {});
  const extra = useSelector(state => state.user.user.userinfo?.extra || null);
  const [isregula, setIsregula] = useState(false);
  const [action, setAction] = useState(false);
  const [checked, setChecked] = useState(false);
  const [attemptsError, setAttemptsError] = useState(false);

  let slug = getSlug();

  useEffect(() => {
    if (extra && games.length > 0) {
      const newExtra = JSON.parse(extra);
      if (newExtra.accepted_privacy.find(id => id === games[0]?.id)) {
        setIsregula(false);
      } else {
        setIsregula(true);
      }
    }
  }, [extra, games]);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  const {
    settings: {
      aspect: { pregame },
    },
    i18l: {
      it: { textUp, buttonLabel },
    },
  } = rules;

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  const clickFinalHandler = index => {
    console.log(props);
    setAction(true);
  };
  const clickHandler = () => {
    setChecked(true);
    dispatch(acceptGameRules(id, slug));
    /*   let endpoint = `/game/${slug}/${games[0].id}/acceptpromotionprivacy`;
    customAxios.post(endpoint).then(() => {
      dispatch(getUserInfo());
      setIsregula(false);
    }); */
  };

  return (
    <>
      <React.Fragment>
        {loading || action ? (
          <Loader show={loading} />
        ) : (
          <>
            {!gameinpage ? (
              gamePrivacyAccepted ? (
                <Container>
                  <Text primary size={16} bold className='title-participe'>
                    {content}
                  </Text>
                  <div className='content-participe'>
                    <img src={pregame} alt='' />
                    <div
                      className='overlay'
                      onClick={
                        stop_attempts_after_win && already_won
                          ? () => setAttemptsError(true)
                          : () => clickFinalHandler()
                      }
                    >
                      <HandOpen />
                    </div>
                  </div>
                </Container>
              ) : (
                <>
                  {/*  <Text primary size={16} margin='0 15px'>
                {content}
              </Text> */}
                  {/*  <div className='content-participe'>
                <img src={iconParticipe} alt='' />
                <div className='overlay'>
                  <HandOpen />
                </div>
              </div> */}

                  <OverlayRegola className='overlay_regola'>
                    <Text bold className='text-regola'>
                      Accetta il regolamento per
                      <br />
                      partecipare al concorso.
                    </Text>
                    <Flex className='regola' align='center'>
                      <a href={regolamento} target='_blank' rel='noopener noreferrer'>
                        Regolamento
                      </a>

                      <Switch
                        checked={checked}
                        onChange={clickHandler}
                        onColor='#86d3ff'
                        onHandleColor='#2693e6'
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                        height={20}
                        width={48}
                        className='react-switch'
                        id='material-switch'
                      />
                    </Flex>
                  </OverlayRegola>
                </>
              )
            ) : (
              <Container>
                <Text primary size={16} bold className='title-participe'>
                  {content}
                </Text>
                <div className='content-participe'>
                  <img src={pregame} alt='' />
                  <div
                    className='overlay'
                    onClick={
                      stop_attempts_after_win && already_won
                        ? () => setAttemptsError(true)
                        : () => clickFinalHandler()
                    }
                  >
                    <HandOpen />
                  </div>
                </div>
              </Container>
            )}
          </>
        )}
        {action ? (
          <Dynamic
            id={id}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            sid={sid}
            {...props}
          />
        ) : null}
      </React.Fragment>
      <Modal
        show={attemptsError}
        close={() => {
          setAttemptsError(false);
        }}
      >
        {
          /* allowedToPlay ? (
          <p>
            Attenzione, oggi non puoi giocare. Riprova{' '}
            {days
              .map(day => {
                switch (day) {
                  case 1:
                    return 'Lunedì';
                  case 2:
                    return 'Martedì';
                  case 3:
                    return 'Mercoledì';
                  case 4:
                    return 'Giovedì';
                  case 5:
                    return 'Venerdì';
                  case 6:
                    return 'Sabato';
                  case 7:
                    return 'Domenica';
                  default:
                    break;
                }
              })
              ?.join(', ') + '.'}
          </p>
        ) :  */ already_won ? (
            <p>Hai già vinto un premio per questo gioco.</p>
          ) : (
            <p>Per oggi hai terminato i tentativi di gioco.</p>
          )
        }
      </Modal>
    </>
  );
};

Click.propTypes = {};

export default Click;
