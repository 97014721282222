import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import useForm from '../../hooks/useForm';
import { firstAccessForm } from '../../formsConfig/formsConfig';
import { Input, Button, Loader, Text, Col, Flex } from '../../components/Ui';
import ContentSlide from '../../components/ContentSlide';
import { respondTo } from '../../theme/mixin';
import { activateUser } from '../../store/actions/auth';
import Footer from '../../components/Footer/Footer';
import { hotjar } from 'react-hotjar';
import { calculateAge, isValidDate } from '../../utils/utils';
import { useHistory } from 'react-router';
import Fade from '../../components/Fade';
import { Wrapper } from '../../components/Ui';
import Banner from '../../components/banner';
import bannerHome from '../../assets/images/bannerHome.png';
import imgMobile from '../../assets/images/bannerHome.png';
import BoxLayout from '../../components/Ui/BoxLayout';

const FirstAccess = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector(state => state.user.user.userinfo);
  const firstAccess = useSelector(state => state.user.user.first_access_done);
  const error = useSelector(state => state.user.firstAccessError);
  const loading = useSelector(state => state.user.firstAccessLoading);
  const footercontent = useSelector(state => state.cms.footer);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const activateUserHanlder = () => {
    const obj = {
      accept_rules: formData['rules'].value,
      gdpr_privacy: formData['privacy'].value,
      nickname: formData['nickname'].value,
      avatar: formData['avatar'].value,
    };

    dispatch(activateUser(obj));
  };

  useEffect(() => {
    hotjar.initialize(3032126, 6);
  }, []);

  useEffect(() => {
    if (firstAccess) {
      props.history.push('/');
    }
  }, [firstAccess, props.history]);

  let datePickerNames = [];
  const accessForm = firstAccessForm();
  for (var prop in accessForm) {
    if (accessForm[prop].elementType === 'datepicker') {
      datePickerNames.push(accessForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    startDate,
    showError,
    datePickerHandler,
    resetFormHandler,
  } = useForm(activateUserHanlder, accessForm, datePickerNames, setSelectedAvatar);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        changedDatePicker={datePickerHandler}
        resetFormHandler={resetFormHandler}
        startdate={startDate}
        radioInputs={inp.inputs}
        setSelectedAvatar={setSelectedAvatar}
        selectedAvatar={selectedAvatar}
        own_login_adult='true'
        {...inp}
      />
    );
  });

  return (
    <>
      <Container>
        <Fade>
          <Wrapper isSelect={isSelect}>
            <Banner>
              <HiwBanner>
                <ContentSlide
                  title='Completa la registrazione'
                  noBtn
                  img={bannerHome}
                  darkText={null}
                  imgMobile={imgMobile}
                />
              </HiwBanner>
            </Banner>
            <ContentBox>
              <BoxLayout width='100%' height='100%'>
                <Text as='h4' size={14} upper bold>
                  I tuoi dati
                </Text>
                <Flex className='row'>
                  <Col width={50} className='firstCol'>
                    <Text size={8} upper className='label'>
                      Nome
                    </Text>
                    <Text size={14} bold>
                      {userInfo && userInfo.firstname}
                    </Text>
                  </Col>
                  <Col width={50} className='firstCol'>
                    <Text size={8} upper className='label'>
                      Cognome
                    </Text>
                    <Text size={14} bold>
                      {userInfo && userInfo.lastname}
                    </Text>
                  </Col>
                </Flex>
                <Flex className='row' align='center'>
                  <Col width={50} className='firstCol'>
                    <Text size={8} upper className='label'>
                      Email
                    </Text>
                    <Text size={14} bold>
                      {userInfo && userInfo.email}
                    </Text>
                  </Col>
                  <Col width={50} className='firstCol'>
                    <div className='nickname'>{inputs[2]}</div>
                  </Col>
                </Flex>
                <Text as='h4' size={14} upper bold>
                  Avatar
                </Text>
                <div className='row-32'>{inputs[3]}</div>
                <Text as='h4' size={14} upper bold>
                  consensi
                </Text>
                <div className='single-wrapper'>
                  <div className='single-consens'>{inputs[0]}</div>
                  <div className='single-consens'>{inputs[1]}</div>
                </div>
                {error && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: 11,
                      marginTop: -15,
                      marginBottom: 20,
                      display: 'block',
                    }}
                  >
                    {error}
                  </span>
                )}
                <Flex justify='end'>
                  <form onSubmit={formSubmitHandler}>
                    <Button center upper disabled={loading} active>
                      {loading ? (
                        <Loader show={loading} white inBtn />
                      ) : (
                        'COMPLETA LA REGISTRAZIONE'
                      )}
                    </Button>
                  </form>
                </Flex>
              </BoxLayout>
            </ContentBox>
          </Wrapper>
        </Fade>
      </Container>

      <Footer footercontent={footercontent} />
    </>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
  .profile {
    z-index: -1;
  }
  .flex {
    margin-bottom: 30px;
    background-color: transparent;
    margin-top: -250px;
    ${respondTo.sm` 
      margin-top: -50px; 
    `};
  }
  .touiPunti {
    align-self: flex-start;
    ${respondTo.ml`
        align-self:initial;    
    `};
  }
  .contentModal {
    padding: 0;
    background-color: transparent;
    width: 100%;
    border-radius: 15px;
  }
`;

const HiwBanner = styled.div`
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: top;
    `};
  }
`;
const ContentBox = styled.div`
  margin-bottom: 70px;
  background-color: transparent;
  & > div {
    min-height: 430px;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
  > div {
    padding-top: 4px;
    h4 {
      margin-bottom: 14px;
    }
  }
  .firstCol {
    padding-left: 5px;
    margin-bottom: 30px;
  }
  .label {
    margin-bottom: 4px;
  }
  .row-32 {
    margin-bottom: 32px;
  }
  .nickname {
    > div {
      margin-bottom: 0;
    }
    #nickname {
      font-size: 12px;
    }
  }
  .single-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .single-consens {
      width: 100%;
      padding-left: 5px;
      ${respondTo.sm` 
      width: 50%;
    `};
    }
  }
`;

export default FirstAccess;
