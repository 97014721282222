import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Text } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import Footer from '../../components/Footer/Footer';
import { hotjar } from 'react-hotjar';
import { useHistory } from 'react-router';

const ThankYou = () => {
  const history = useHistory();
  const footercontent = useSelector(state => state.cms.footer);

  useEffect(() => {
    hotjar.initialize(3032126, 6);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('redirectUrl')) {
        history.push(localStorage.getItem('redirectUrl'));
      } else {
        history.push('/');
      }
    }, 4000);
    return () => localStorage.removeItem('redirectUrl');
  }, [history]);

  return (
    <>
      <Wrapper>
        <div className='sides-wrapper'>
          <div className='bg-image'>
            <img src={process.env.PUBLIC_URL + '/images/firstaccess-image.png'} alt='' />
          </div>

          <div className='right-side'>
            <Text primary align='center' light size={40}>
              Grazie
              <br />
              per esserti registrato.
            </Text>

            <Text size={20} className='mt-10'>
              Tra pochi istanti verrai reindirizzato alla home.
            </Text>
          </div>
        </div>
      </Wrapper>
      <Footer footercontent={footercontent} />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .bg-image {
    position: absolute;
    left: 15px;
    top: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .single-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .single-consens {
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      color: #a3aec5;
      font-weight: 500;

      a {
        text-decoration: underline;
      }
    }
  }

  .sides-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
    background-color: #fff;
    position: relative;

    ${respondTo.sm`
      flex-wrap: nowrap;
    `};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .right-side {
      width: 470px;
      background-color: #fff;
      padding: 15px;
      margin: 10px;
      min-height: 600px;
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      ${respondTo.sm`
        margin: 0 15px 0 0;
      `};

      h4 {
        font-size: 18px;
        color: #fff;
        margin-bottom: 15px;
      }

      p {
        font-size: 12px;
        margin-bottom: 25px;
      }
    }
  }
`;

export default ThankYou;
