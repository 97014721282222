import React from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Box, Input } from '../../../Ui';

import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';

import { respondTo } from '../../../../theme/mixin';
import { concorsoForm } from '../../../../formsConfig/formsConfig';
import useForm from '../../../../hooks/useForm';
import Fade from '../../../Fade';
import customAxios from '../../../../config/axios-refresh-token';
import { getSlug } from '../../../../utils/utils';
import moment from 'moment';
import { getGame } from '../../../../store/actions';

const Ratephoto = props => {
  const {
    g: { id, settings },
    pdf,
  } = props;
  const slug = getSlug();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const [info, setInfo] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const dataForm = concorsoForm({ maxDate: new Date(settings?.end), pdf });
  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const submitHandler = e => {
    // e.preventDefault();
    setIsLoading(true);
    const dataToSend = {};
    for (const property in formData) {
      if (property !== 'file') {
        dataToSend[property] =
          property !== 'photo_date' ? formData[property].value : formData[property].valuetosend;
      }
    }

    var data = new FormData();

    let fileArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
        //dataToSend['photo_title'] = element.file.name?.split('.')?.[0];
      }
    });

    data.append('data', JSON.stringify(dataToSend));

    data.append('section', 'interaction');

    customAxios
      .post(`game/${slug}/${id}/ratephoto`, data)
      .then(response => {
        dispatch(getGame(id, slug)).then(() => {
          setIsLoading(false);
        });
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    onSetFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={inp.value}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        setFormValid={onSetFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        concorso
        lang='it'
        {...inp}
      />
    );
  });

  return (
    <Box>
      <div>
        <Text primary className='mb-10'>
          1. CARICA LA FOTO
        </Text>
        <Flex row={15}>
          <Col width={50}>{inputs[0]}</Col>
          {/*  <Col width={50}>
            <Button border hoverGrey fullwidth style={{ justifyContent: 'center' }}>
              SCATTA
            </Button>
          </Col> */}
        </Flex>
      </div>
      <form onSubmit={formSubmitHandler}>
        <Text primary className='mt-20'>
          2. SCEGLI UN ATELIER E INDICA LA DATA DEL MATRIMONIO
        </Text>
        <Flex align='end' row={15}>
          <Col width={100}>{inputs[1]}</Col>
          <Col width={50}>{inputs[2]}</Col>
          <Col width={50}>{inputs[3]}</Col>
        </Flex>
        {inputs[4]}
        {inputs[5]}
        <Text size={14}>
          <a
            href={`${process.env.PUBLIC_URL}/pdf/INFORMATIVA PRIVACY Gamification.pdf`}
            target='_blank'
            style={{ display: 'inline-block' }}
          >
            L'informativa privacy
          </a>{' '}
          connessa alla raccolta dei dati e al caricamento del contributo
        </Text>
        <hr style={{ borderColor: '#aaaaa314', margin: '0 -15px' }} />
        <Flex justify='flex-end' align='center' style={{ marginTop: '20px' }}>
          <Button
            type='submit'
            active
            hover
            padding='0 50px'
            loading={isLoading}
            style={{ justifyContent: 'center', width: '47%' }}
          >
            Invia
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Ratephoto;
