import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import customaxios from '../../config/axios-refresh-token';
import { contactForm } from '../../formsConfig/formsConfig';

import { Link } from 'react-router-dom';
import { Input, Button, Box, Text, Loader } from '../Ui';
import Heading from '../Ui/heading';
import img1 from '../../assets/images/iconContactBox.svg';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';
const CustomBox = styled(Box)`
  width: 100%;
  height: 100%;
  ${respondTo.sm`
     
  `}
`;
const Form = styled.form`
  .label-contact {
    color: #03c2e2;
    font-size: 10px;
  }
  .select-contact {
    option {
      color: #002d72;
    }
    & option:first-child {
      color: #47545d59;
    }
    &:invalid,
    & option[value=''] {
      color: #47545d59;
    }
  }
  .btn {
    padding: 0 24px;
    margin: 0 0 0 auto;
  }
`;
const Contact = ({ setModal }) => {
  // const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    customaxios
      .post('/app/contactus', { topic: formData.topic.value, message: formData.message.value })
      .then(response => {
        if (response.status === 200) {
          setModal(true);
          setLoading(false);
          resetFormHandler();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit, resetFormHandler } =
    useForm(submitHandler, contactForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        resetFormHandler={resetFormHandler}
      />
    );
  });

  const contactcms = useSelector(state => state.cms.contact);

  const contact_image = contactcms[0]?.contents?.find(e => e.name === 'contact_image');
  const contact_text = contactcms[0]?.contents?.find(e => e.name === 'contact_text');

  return (
    <CustomBox padding='15px'>
      <Heading img={img1} text='SCRIVICI' />
      <Text as='p' size={12}>
        Ti ricontatteremo nel più breve tempo possibile.
      </Text>

      <Form onSubmit={formSubmitHandler}>
        {inputs}

        <Button active upper size='14px' hover className='btn btn-contact'>
          {loading ? <Loader inBtn show={true} /> : <span>Invia</span>}
        </Button>
      </Form>
    </CustomBox>
  );
};

export default Contact;
