import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import { Text, Button } from '../../../Ui';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-awesome-styled-grid';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGame } from '../../../../store/actions';
import useMedia from '../../../../hooks/useMedia';
import ResultGame from '../../Response/ResultGame';
import PreInteraction from '../../PreInteraction';
import { Click } from '../../Interactions';

const Cont = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px 16px;
  border-radius: 16px;
  box-shadow: 0px 2px 15px #00000029;
`;
const TextContainer = styled.div`
  margin-bottom: 22px;
  ${respondTo.sm`
		margin-bottom: 30px;
	`}
`;

const ThankYouPage = props => {
  let cor_answ = null;
  let iwGame = false;
  const dispatch = useDispatch();
  const answers = JSON.parse(useSelector(state => state.promo?.game?.[0]?.interaction?.data));

  let gameSlug = null;
  let gameId = null;
  if (props.correct_answ !== null) {
    cor_answ = props.correct_answ;
  } else {
    cor_answ = props.g.log.correct_answers ? props.g.log.correct_answers : 0;
  }
  if (props.iwGame?.extraAttempt) {
    iwGame = true;
    gameSlug = props.iwGame.extraAttemptPromoSlug;
    gameId = props.iwGame.extraAttemptGameId;
  } else {
    if (props.g.log.extraAttempt) {
      iwGame = true;
      gameSlug = props.g.log.extraAttemptPromoSlug;
      gameId = props.g.log.extraAttemptGameId;
    } else {
      iwGame = false;
    }
  }
  useEffect(() => {
    let gameInpage = true;
    if (iwGame) {
      dispatch(getGame(gameId, gameSlug, '', gameInpage, cor_answ));
    }
  }, []);

  return (
    <>
      {!iwGame ? (
        <Cont>
          <TextContainer>
            <Text as='p' size={24} bold color='#172344'>
              Oggi non puoi partecipare all’Instant Win: peccato!
            </Text>
            <Text as='p' size={14} margin={'12px 0 0 0'} color='#172344'>
              Hai risposto correttamente soltanto a {cor_answ}{' '}
              {cor_answ === 1 ? 'domanda' : 'domande'}.
            </Text>
            <Text as='p' size={14} margin={'0 0 32px 0'} color='#172344'>
              Ti ricordiamo che per accedere all’Instant Win devi fornire almeno{' '}
              {answers?.settings?.extra_attempt_min_corrects}{' '}
              {+answers?.settings?.extra_attempt_min_corrects === 1
                ? 'risposta esatta'
                : 'risposte esatte'}
              .
            </Text>
            <Link to='/'>
              <Button active upper hover arrowRight style={{ marginLeft: 'auto' }}>
                TORNA IN HOMEPAGE
              </Button>
            </Link>
          </TextContainer>
        </Cont>
      ) : (
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Cont>
              <TextContainer>
                <Text as='p' size={24} bold color='#172344'>
                  Grazie per aver partecipato!
                </Text>
                <Text as='p' size={14} margin={'12px 0 0 0'} color='#172344'>
                  Hai risposto correttamente a {cor_answ} domande.
                </Text>
                <Text as='p' size={14} margin={'0 0 32px 0'} color='#172344'>
                  Partecipa subito all’Instant Win. <br /> <br /> Scopri se la valigia contiene un
                  regalo per te. Segui le istruzioni che trovi nel riquadro accanto.
                </Text>
                <Link to='/'>
                  <Button active upper hover arrowRight style={{ marginLeft: 'auto' }}>
                    TORNA ALLA HOME PAGE
                  </Button>
                </Link>
              </TextContainer>
            </Cont>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Cont></Cont>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ThankYouPage;
