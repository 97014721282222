import React from 'react';
import ContentSlide from '../ContentSlide';
import styled from 'styled-components';
import PointsGame from '../QuizGame/pointsGame';
import { respondTo } from '../../theme/mixin';
import sunnyFridayBoxBackground from '../../assets/images/bgInvitAmico.jpg';
import BoxLayout from '../Ui/BoxLayout';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

const Container = styled.div`
  height: 100%;
  .hiw-text {
    width: 60%;
  }
`;

const SpanConcorso = styled.strong`
  width: 350px;
  position: relative;
  bottom: -10px;
  font-size: 10px;

  ${respondTo.sm`
    position: absolute;
    bottom: 10px;
    left: 10px;
  `}
`;

function SunnyBoxPrizes({ fromConcorso, home, lancio, game, title, img, width, ...rest }) {
  const currentPrize = useSelector(state => state.game?.games[0]);

  return (
    <BoxLayout
      icon={img}
      width={width}
      title={title}
      styling='margin: 0 -15px -15px -15px;  border-radius: 15px; overflow: hidden;'
      {...rest}
    >
      <PointsGame fromConcorso />
      <Container home={home}>
        <ContentSlide
          text={
            <>
              PARTECIPA ORA!
              <br />
              Oggi in palio un {currentPrize?.prize_name && parse(currentPrize?.prize_name)}. *
              <br />
              <br />
              Che aspetti? Basta un click!
              <br />
              <SpanConcorso>
                * MA RICORDA: potrebbero esserci in palio anche eventuali viaggi presenti a
                regolamento che non sono stati assegnati nei giorni precedenti!
              </SpanConcorso>
            </>
          }
          title='PARTECIPA E VINCI UNA VACANZA!'
          img={sunnyFridayBoxBackground}
          isLogged
          link='/premiPalio'
          left
        />
      </Container>
    </BoxLayout>
  );
}

export default SunnyBoxPrizes;
