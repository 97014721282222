import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';

export const getPartnerList = () => {
  let token = localStorage.getItem('token');
  let endpoint = `partners`;
  return dispatch => {
    return customaxios
      .get(endpoint)
      .then(response => {
        dispatch(getPartnerSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const getPartnerSuccess = data => {
  return {
    type: actionTypes.GET_PARTNERS_SUCCESS,
    partnerList: data,
  };
};
