import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Heading from '../Ui/heading';
import icon from '../../assets/images/iconInvitAmico.svg';
import { Button, Text } from '../Ui';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../Ui/BoxLayout';
import bg2 from '../../assets/images/imgInvitAmico.png';
import bg1 from '../../assets/images/bgInvitAmico.jpg';
import rankDown from '../../assets/images/ranking-arrow-down.svg';
import rankUp from '../../assets/images/ranking-arrow-up.svg';
import { useSelector } from 'react-redux';
const Content = styled.div`
  height: 100%;
  .content {
    margin: 10px 0;
    width: 100%;
    background-color: ${({ theme }) => theme.bgColorDiv};
    border-radius: 15px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      gap: 22px;
      padding: 20px 40px;
      color: grey;
      &.myaccount {
        background-color: ${({ theme }) => theme.text_grey};
        color: white;
      }
      .col-3 {
        width: calc(100% * 3 / 12);
        &.last {
          text-align: right;
        }
      }
      .col-6 {
        width: calc(100% * 6 / 12);
      }
    }
  }
`;
const RankPagination = styled.div`
  border-top: 2px solid grey;
  margin: 5px 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-end;

  .arrow {
    cursor: pointer;
  }

  .arrow:nth-child(2) {
    margin-left: 20px;
  }
`;

const ClassificaGenerale = () => {
  const [rankingView, setRankingView] = useState({ min: 0, max: 4 });

  const [currentRanking, setCurrentRanking] = useState(null);
  const [imInRanking, setImInRanking] = useState(false);
  const currentUser = useSelector(state => state.user?.user?.userinfo);
  const ranking_generale = useSelector(state => state.user?.user?.ranking);

  useEffect(() => {
    if (currentUser && ranking_generale) {
      setCurrentRanking(ranking_generale);
    }
  }, [currentUser, ranking_generale]);

  const manageRankingViewHandler = type => {
    let newRankingView = { ...rankingView };

    if (type === 'down' && newRankingView.min > 0) {
      newRankingView.min = newRankingView.min - 1;
      newRankingView.max = newRankingView.max - 1;
    } else if (type === 'up' && currentRanking.length > rankingView.max) {
      newRankingView.min = newRankingView.min + 1;
      newRankingView.max = newRankingView.max + 1;
    }

    setRankingView(newRankingView);
  };
  useEffect(() => {
    if (currentRanking) {
      const userIndex = currentRanking.findIndex(rank => rank.id === currentUser?.id);
      setRankingView({
        min: userIndex > 2 ? userIndex - 2 : userIndex,
        max:
          userIndex < 19
            ? userIndex === 0 || userIndex === 1 || userIndex === 2
              ? userIndex + 4
              : userIndex + 2
            : userIndex,
      });

      let myPosition = currentRanking?.filter(ele => ele.my_position === true);

      setImInRanking(myPosition);
    }
  }, [currentUser?.customer_id, currentRanking]);
  return (
    <BoxLayout title='classifica generale' width='60%'>
      <Content>
        {!imInRanking && <Text color='red'>Non sei in classifica, inizia subito a giocare!</Text>}
        <div className='content'>
          {currentRanking?.slice(rankingView.min, rankingView.max).map((rank, rankIndex) => (
            <Text
              className={`row ${rank.my_position ? 'myaccount' : ''}`}
              key={rankIndex}
              size={20}
            >
              <div className='col-3'>{rank.position}</div>
              <div className='col-6'>{rank.nickname}</div>
              <div className='col-3 last'>{rank.score}</div>
            </Text>
          ))}
          <RankPagination>
            {currentRanking ? (
              <>
                <div className='arrow' onClick={() => manageRankingViewHandler('up')}>
                  <img src={rankDown} alt='' />
                </div>

                <div className='arrow' onClick={() => manageRankingViewHandler('down')}>
                  <img src={rankUp} alt='' />{' '}
                </div>
              </>
            ) : (
              ''
            )}
          </RankPagination>
        </div>
      </Content>
    </BoxLayout>
  );
};

export default ClassificaGenerale;
