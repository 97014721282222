import React from 'react';
import Heading from '../Ui/heading';

import ContentSlide from '../ContentSlide';
import styled from 'styled-components';
import PointsGame from './pointsGame';
import { respondTo } from '../../theme/mixin';
import img1 from '../../assets/images/alpitour1.png';
import img2 from '../../assets/images/boxTravelGame_home.png';
import comingSoonImage from '../../assets/images/coming-soon-box.png';
import imgMobile from '../../assets/images/boxTravelGame_home_mobile.png';
import imgMobileHero from '../../assets/images/homeMobile.png';
import bgDesk from '../../assets/images/sectionDesk.png';
import bgMobile from '../../assets/images/sectionMob.png';
import { Flex } from '../Ui';
import BoxLayout from '../Ui/BoxLayout';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
const Container = styled.div`
  height: 100%;

  .hiw-text {
    width: 60%;
    /*     ${respondTo.md`
     ${({ home }) => home && 'margin-left: auto'};
     ${({ home }) => home && 'margin-top: 10%'};
    `} */
  }
`;

const SpanConcorso = styled.span`
  width: 350px;
  position: relative;
  bottom: -10px;
  font-size: 10px;

  ${respondTo.sm`
    position: absolute;
    bottom: 10px;
  `}
`;

function QuizGame({ fromConcorso, home, game, title, img, info, width, ...rest }) {
  const location = useLocation();
  const isLogged = useSelector(state => state.auth.token);
  const currentPrize = useSelector(state => state.game?.games[0]);

  return (
    <BoxLayout
      width={width}
      title={title}
      styling='margin: 0 -15px -15px -15px;  border-radius: 15px; overflow: hidden;'
      bg2={home ? bgDesk : null}
      bgMob={home ? bgMobile : null}
      {...rest}
    >
      <Container>
        <ContentSlide
          text={
            isLogged ? (
              info.i18l.subtitle
            ) : (
              <>
                <strong>Registrati subito</strong> per scoprire in anteprima i prossimi concorsi,{' '}
                <strong>potrai vincere viaggi da sogno</strong> nelle{' '}
                <strong>migliori mete del mondo...</strong> e non solo!
              </>
            )
          }
          title={info.i18l.title}
          img={info.img_box}
          imgMobile={info.img_box}
          isLogged
          link={`/concorsi/${info.slug}`}
          left
          darkText={info.dark_text}
          textBtn='PARTECIPA'
          //noBtn={isLogged}
          partecipaText={!isLogged}
        />
      </Container>
    </BoxLayout>
  );
}

export default QuizGame;
