import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import { FiCopy } from 'react-icons/fi';
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@styled-icons/material';
const ButtonWrap = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: ${props => (props.loading || props.disabled ? 'default' : 'pointer')};
  outline: none;
  border: ${({ border, theme, primary, hoverWhite }) =>
    primary
      ? '1px solid' + theme.primary
      : border
      ? '1px solid' + theme.text_grey
      : hoverWhite
      ? '1px solid #fff'
      : 'none'};
  border-radius: 5px;
  color: ${({ color, primary, theme, border, hoverWhite, white, darkText }) =>
    color
      ? color
      : primary || (!darkText && darkText !== undefined)
      ? theme.primary
      : border
      ? theme.text_grey
      : hoverWhite
      ? '#fff'
      : theme.default_colortext};
  padding: ${({ padding }) => (padding ? padding : '0 10px')};
  background-color: ${({ active, theme, bgColor, white }) =>
    active
      ? theme.primary_button
      : bgColor
      ? bgColor
      : white
      ? theme.default_colortext
      : 'transparent'};
  transition: all 0.3s;
  height: ${({ height }) => (height ? height : '30px')};
  font-size: ${({ size }) => (size ? size : '11px')};
  font-weight: ${({ bold }) => (bold ? '700' : '100')};
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  opacity: ${props => (props.loading || props.disabled ? '.5' : '1')};
  width: ${props => (props.fullwidth ? '100%' : props.width ? props.width : 'auto')};
  line-height: 1;

  &:hover {
    ${({ hover, theme, hoverGrey, hoverWhite }) =>
      hover || hoverGrey || hoverWhite
        ? css`
            background-color: ${({ theme, hover, hoverGrey, hoverWhite }) =>
              hover
                ? theme.hover_primary_button
                : hoverGrey
                ? theme.text_grey
                : hoverWhite
                ? '#fff'
                : theme.primary};
            color: ${({ theme, hoverWhite }) => (hoverWhite ? '#0051CE' : theme.default_colortext)};
          `
        : ''}
  }
  a {
    color: ${({ theme }) => theme.default_colortext};
  }
  & > svg {
    right: -10px;
  }
  svg {
    position: relative;

    top: -1px;
    font-size: ${({ sizeIcon }) => (sizeIcon ? sizeIcon : '35px')};
    color: ${({ colorSvg, color }) => (colorSvg ? colorSvg : color)};
  }
`;

const Button = props => {
  return (
    <ButtonWrap {...props}>
      {props.loading ? (
        <Loader show={true} inBtn white />
      ) : (
        <>
          {props.children}
          {props.arrowDown ? (
            <KeyboardArrowDown size={25} />
          ) : props.arrowLeft ? (
            <ChevronLeft size={25} />
          ) : props.copy ? (
            <FiCopy />
          ) : props.noIcon ? null : (
            <ChevronRight size={25} />
          )}
        </>
      )}
    </ButtonWrap>
  );
};

Button.defaultProps = {};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
  loading: PropTypes.bool,
  white: PropTypes.bool,
};

export default Button;
