import React from 'react';
import styled from 'styled-components';
import Footer from '../../../components/Footer';
import { useSelector } from 'react-redux';
import backgroundImage from '../../../assets/images/page-not-found.png';
import logo from '../../../assets/images/logo-alpitour-world.png';
import { Button, Text } from '../../../components/Ui';
import { Link } from 'react-router-dom';
import { respondTo } from '../../../theme/mixin';

const Page404 = () => {
  const footercontent = useSelector(state => state.cms.footer);
  return (
    <Page404Wrapper>
      <div className='hero-content'>
        <div className='content'>
          <img src={logo} alt='' />
          <Text size={32} primary bold className='text'>
            Attenzione:
            <br />
            pagina non trovata
          </Text>
          <div className='line'></div>
          <Text className='mb-20'>Siamo spiacenti, prova a tornare alla home</Text>
          <Link to='/'>
            <Button active upper hover>
              Torna alla homepage
            </Button>
          </Link>
        </div>
        <img src={backgroundImage} alt='' />
      </div>
      {/*  <Footer footercontent={footercontent} /> */}
    </Page404Wrapper>
  );
};

const Page404Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  .hero-content {
    display: block;
    position: relative;
    height: 800px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    margin-bottom: 60px;

    .content {
      position: absolute;
      z-index: 1;
      padding: 0 20px;
      width: 100%;
      top: 70px;

      ${respondTo.sm`
        width: auto;
        padding: 0;
        right: 200px;
      `};

      .text {
        line-height: 1;
      }

      .line {
        width: 50px;
        height: 5px;
        border-radius: 4px;
        background-color: ${props => props.theme.primary};
        margin-top: 15px;
        margin-bottom: 10px;
      }

      img {
        width: 250px;
        margin-bottom: 65px;
      }
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default Page404;
